import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { homeState } from './home.reducer';
import { headerState } from './header.reducer';
import { footerState } from './footer.reducer';
import { servicesState } from './services.reducer';

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  homeState,
  headerState,
  footerState,
  servicesState
});

export default createRootReducer;
