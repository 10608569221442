import React from 'react';

import circulargratimg from '../../../assets/images/service-images/gratings/circular_gratings.png';
import elecforgratimg from '../../../assets/images/service-images/gratings/electroforged_gratings.jpg';
import heavydutygratimg from '../../../assets/images/service-images/gratings/heavy_duty_gratings.jpg';
import honeycombgratimg from '../../../assets/images/service-images/gratings/honeycomb_gratings.jpg';
import intlockgratimg from '../../../assets/images/service-images/gratings/interlock_gratings.jpeg';
import psurfgratimg from '../../../assets/images/service-images/gratings/plane_surface_gratings.jpg';
import serratedgratimg from '../../../assets/images/service-images/gratings/serrated_gratings.jpg';
import stairtredgratimg from '../../../assets/images/service-images/gratings/stair_treads.jpg';

const GRATINGS_CONTENT = {
  p1: 'ZINCO STEEL WORKS manufacture and supplies Manual Gratings as well as electro forged grating panels, cut-to-size or custom fabricated gratings, circular gratings, heavy duty gratings and stair treads with required fixing clamps. These gratings can be supplied in self-colour, hot dip galvanized, and Painted finish. For long life of gratings, can be hot -dip galvanized, it is a treatment providing rust proofing and maintenance free replacement, thus gratings can stay hygienic, clean and bright finish. High strength, a very important factor which our gratings consist, measures the toughness, durability of the product. The gratings are offered with plain as well as serrated bearing bars. Gratings are made from various materials for different applications, the most common being mild steel, our gratings are made from MS confirming to IS 2062 GR A.',
  p2: 'Zinco Steel Gratings have some magnificent features such as it is light in Good strength, Large Carrying Capacity, Cost Effective. Gratings offer good ventilation, easy clean and easy installation.',
  applications: [
    'Industrial floorings - Maintenance Walkways',
    'Drain Covers - Drainage',
    'Stairways and Catwalks',
    'Marine Industries',
    'Tower Packing Support',
    'Power Plant',
    'Bridge Flooring',
    'Walkways',
    'Platforms',
    'Mezzanine Floors'
  ],
  manual_gratings: [
    'Plane Surface Gratings',
    'Serrated Gratings',
    'Honeycomb Type Gratings',
    'Circular Gratings',
    'Heavy Duty Gratings',
    'Interlock Type Gratings'
  ],
  images: {
    planesurface_gratings: {
      name: 'Plane Surface Gratings',
      img: psurfgratimg
    },
    serrated_gratings: {
      name: 'Serrated Gratings',
      img: serratedgratimg
    },
    honeycomb_gratings: {
      name: 'Honey Comb Gratings',
      img: honeycombgratimg
    },
    cicular_gratings: {
      name: 'Circular Gratings',
      img: circulargratimg
    },
    heavyduty_gratings: {
      name: 'Heavy Duty Gratings',
      img: heavydutygratimg
    },
    interlock_gratings: {
      name: 'Interlocked Gratings',
      img: intlockgratimg
    },
    electroforged_gratings: {
      name: 'Electroforged Gratings',
      img: elecforgratimg
    },
    stairtreads: {
      name: 'Stair Treads',
      img: stairtredgratimg
    }
  }
};

const Gratings = ({ ...props }) => {
  return <div className='gratings-container'>
        <div className='content-div'>
          <h4>GRATINGS</h4>
            <p>
                {GRATINGS_CONTENT.p1}
            </p>
            <p>
                {GRATINGS_CONTENT.p2}
            </p>
            <h6><b>Grating Applications:</b></h6>
            <ul>
                {GRATINGS_CONTENT.applications.map((ap, ix) => <li key={ix}>{ap}</li>)}
            </ul>
            <h6><b>Types of Gratings</b></h6>
            <ol>
                <li>
                    Manual Welded Gratings
                <ol>
                        {GRATINGS_CONTENT.manual_gratings.map((mg, ix) => <li key={ix}>{mg}</li>)}
                    </ol>
                </li>
                <li>
                    Electroforged Gratings
            </li>
                <li>
                    Stair Treads
            </li>
            </ol>
        </div>
        <div className='images-div'>
                {
                    Object.keys(GRATINGS_CONTENT.images).map((im, ix) => <div className='image-container' key={ix}>
                        <img src={GRATINGS_CONTENT.images[im].img}/>
                        <p>{GRATINGS_CONTENT.images[im].name}</p>
                    </div>)
                }
                <div className='image-container'></div>
        </div>
    </div>;
};

export default Gratings;
