import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { sendEmail } from '../../actions/contacts.actions';

class Enquiry extends Component {
    static propTypes = {
      sendEmail: PropTypes.func.isRequired
    }

    state = {
      enquirerName: '',
      enquirerEmail: '',
      enquirerPhone: '',
      enquirerMessage: '',
      canSendMessage: false,
      isEnquiryLoading: false
    }

    submitEnquiry = (ev) => {
      // eslint-disable-next-line no-unused-expressions
      ev.preventDefault();
      const errors = [];
      if (!this.state.enquirerName) { errors.push('Name cannot be empty'); }
      if (!this.state.enquirerEmail && !this.state.enquirerPhone) { errors.push('Email or Phone Number required'); }
      if (!this.state.enquirerMessage) { errors.push('Message cannot be empty'); }

      if (errors.length > 0) {
        for (const err of errors) {
          toast.error(err);
        }
      } else {
        // eslint-disable-next-line no-undef
        const emailBody = `Name: ${this.state.enquirerName}<br>Email: ${this.state.enquirerEmail}<br>Phone: ${this.state.enquirerPhone}<br>Message: ${this.state.enquirerMessage}`;
        this.setState({ isEnquiryLoading: true });
        this.props.sendEmail(this.state.enquirerEmail, `Enquiry - ${this.state.enquirerName}`, emailBody)
          .then(() => this.setState({
            isEnquiryLoading: false,
            enquirerName: null,
            enquirerEmail: null,
            enquirerPhone: null,
            enquirerMessage: null
          }))
          .catch(() => this.setState({
            isEnquiryLoading: false,
            enquirerName: null,
            enquirerEmail: null,
            enquirerPhone: null,
            enquirerMessage: null
          }));
      }
    }

    changeValue = (item, value) => {
      const obj = {};
      obj[item] = value;
      this.setState(obj);
    }

    render () {
      return <div className='enquiry-form-container'>
            <form onSubmit={this.submitEnquiry}>
                <span className='enquiry-title'>Enquire with us:</span>
                <div className='enquiry-item'>
                    <label>Name</label>
                    <input className='form-control' type='text' onChange={(ev) => { this.changeValue('enquirerName', ev.target.value); }} />
                </div>
                <div className='enquiry-item'>
                    <label>Email</label>
                    <input className='form-control' type='email' onChange={(ev) => { this.changeValue('enquirerEmail', ev.target.value); }} />
                </div>
                <div className='enquiry-item'>
                    <label>Phone Number</label>
                    <input className='form-control' type='tel' onChange={(ev) => { this.changeValue('enquirerPhone', ev.target.value); }} />
                </div>
                <div className='enquiry-item'>
                    <label>Message</label>
                    <textarea className='form-control' type='text' onChange={(ev) => { this.changeValue('enquirerMessage', ev.target.value); }} />
                </div>
                <div className='enquiry-item'>
                    {
                        this.state.isEnquiryLoading ? 'Submitting...' : <button className='form-control submit-button'>Submit</button>
                    }
                </div>
            </form>
        </div>;
    }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sendEmail: bindActionCreators(sendEmail, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enquiry);
