import React from 'react';

import sthrimg from '../../../assets/images/service-images/hand_rails/steel_handrail.jpg';

const HAND_RAILS = {
  p1: 'ZINCO STEEL WORKS manufacturer the hand railing of various sizes & shape for industrial and offshore installation also. It is also available with kick plate or without kick plate. Handrails are commonly used while ascending or descending stairways and escalators, in order to prevent injurious falls. Handrails are typically supported by posts or mounted directly to walls.',
  p2: 'It is available in mild steel with standard finish, self colour, painted, galvanized or powder coated. Our railings are recommended on applications where safety is a prime concern in the company. ZINCO STEEL WORKS handrails are elegant and easy to transport. They are also very easy to install and can be fixed by using welding or bolts. '
};

const HandRails = ({ ...props }) => {
  return <div className='hand-rails-container'>
<div className='content-div'>
      <h4>HAND RAILS</h4>
      <p>
        {HAND_RAILS.p1}
      </p>
      <p>
        {HAND_RAILS.p2}
      </p>
      </div>
      <div className='images-div'>
          <div className='image-container'>
            <img src={sthrimg}/>
            <p>Steel Handrails</p>
          </div>
      </div>
    </div>;
};

export default HandRails;
