import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../home';
import AboutPage from '../about';
import ServicesPage from '../services';
import ContactPage from '../contact';
import GalleryPage from '../gallery';
import { changeActiveTabItem } from '../../actions/header.actions';
import { bindActionCreators } from 'redux';

class AppBody extends Component {
    static propTypes = {
    }

    state = {

    }

    render () {
      return <div className='app-body'>
            <Switch>
                <React.Fragment>
                    <Route exact path='/' component={HomePage}/>
                    <Route path='/home' component={HomePage}/>
                    <Route path='/about' component={AboutPage}/>
                    <Route path='/services' component={ServicesPage}/>
                    <Route path='/contact' component={ContactPage}/>
                    <Route path='/gallery' component={GalleryPage}/>

                    <Route path='/gratings' component={ServicesPage}/>
                    <Route path='/cable-trays' component={ServicesPage}/>
                    <Route path='/earthing-materials' component={ServicesPage}/>
                    <Route path='/chequered-plates' component={ServicesPage}/>
                    <Route path='/hand-rails' component={ServicesPage}/>
                </React.Fragment>
            </Switch>
      </div>;
    }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveTabItem: bindActionCreators(changeActiveTabItem, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBody);
