// import img1 from '../assets/images/about/1.jpg';
// import img2 from '../assets/images/about/2.jpg';
import img3 from '../assets/images/about/3.jpg';

export const ABOUT_CONTENT_P1 = 'Zinco Steel Works is one of the leading manufacturers, providing Complete solutions for products like <b>Gratings, Cable Tray Management systems, Chequered Plates and Rod.</b> With Several years of experience in engineering, Zinco Steel Works understands and maintains customer satisfaction at highest level.';

export const ABOUT_CONTENT_P1_S1 = 'Zinco Steel Works is one of the leading manufacturers, providing Complete solutions for products like ';
export const ABOUT_CONTENT_P1_B1 = ' Gratings, Cable Tray Management systems, Chequered Plates and Rod. ';
export const ABOUT_CONTENT_P1_S2 = 'With Several years of experience in engineering, Zinco Steel Works understands and maintains customer satisfaction at highest level.';

export const ABOUT_CONTENT_P2 = 'Zinco Steel Works has full-fledged huge production capacity and our independent Quality Control Department adhere with quality standard specified by our Clients and Consultants. We have an experience of manufacturing Gratings as per Specification of <b>EIL, ONGC, HPCL, BPCL, IOCL, Etc.</b> We provide Gratings and other Products based upon the requirements of our clients and their QAP. We have an experience in design & engineering. We provide technical consultancy for our esteemed clients and has developed the capacity to offer solution to client’s requirement & satisfaction in most effective & economical way.';

export const ABOUT_CONTENT_P2_S1 = 'Zinco Steel Works has full-fledged huge production capacity and our independent Quality Control Department adhere with quality standard specified by our Clients and Consultants. We have an experience of manufacturing Gratings as per Specification of ';
export const ABOUT_CONTENT_P2_B1 = 'EIL, ONGC, HPCL, BPCL, IOCL, Etc. ';
export const ABOUT_CONTENT_P2_S2 = 'We provide Gratings and other Products based upon the requirements of our clients and their QAP. We have an experience in design & engineering. We provide technical consultancy for our esteemed clients and has developed the capacity to offer solution to client’s requirement & satisfaction in most effective & economical way. ';

export const ABOUT_CONTENT_P3 = 'Zinco Steel Works provide quality product at most economical price within a time frame. Utilizing best-in-class raw materials that are sourced from accredited vendors of the industry in the manufacturing process. We partner with our clients from the foundation stage and understand the client\'s requirements in detail. Based upon client\'s need, we provide them with the best solution. We not only offer competitive prices but also share our expertise in view of developing long term business relationships.';

export const ABOUT_IMAGES = [img3];
