import React, { Component } from 'react';
import CableTrays from './pages/cable_trays';
import ChequeredPlates from './pages/chequered_plates';
import EarthingMaterials from './pages/earthing_materials';
import Gratings from './pages/gratings';
import HandRails from './pages/hand_rails';
import ServicesMain from './pages/services.main';

class ServicesContent extends Component {
  static propTypes = {

  }

  render () {
    const ServicesInnerContent = () => {
      if (!window.location.hash) {
        return null;
      }
      switch (window.location.hash.replace('#', '')) {
        case '/gratings':
          return <Gratings />;
        case '/cable-trays':
          return <CableTrays />;
        case '/earthing-materials':
          return <EarthingMaterials />;
        case '/chequered-plates':
          return <ChequeredPlates />;
        case '/hand-rails':
          return <HandRails />;
        default:
          return <ServicesMain />;
      }
    };
    return <div className='services-content-container'>
      <ServicesInnerContent />
    </div>;
  }
}

export default ServicesContent;
