import React, { Component } from 'react';

import { connect } from 'react-redux';
import Carousel from 'react-bootstrap/Carousel';

import im1 from '../../assets/images/carousels/1.jpg';
import im2 from '../../assets/images/carousels/2.jpg';
import im3 from '../../assets/images/carousels/3.jpg';
import im4 from '../../assets/images/carousels/4.jpg';
import im5 from '../../assets/images/carousels/5.jpg';

const INTERVAL = 2000;

class HomePage extends Component {
  static propTypes = {

  }

  render () {
    const images = [im1, im2, im3, im4, im5];
    const names = [
      'Cable Trays',
      'Chequered Plates',
      'Gratings',
      'Steel Structures',
      'Steel Tower Structures'
    ];

    return <React.Fragment>
      <div className='carousel-container'>
        <Carousel>
          {
            images.map((im, ix) => {
              return <Carousel.Item interval={INTERVAL} key={ix}>
                  <div className='carousel-element'>
                    <img src={im} />
                    <span className='carousel-name'>{names[ix]}</span>
                  </div>
              </Carousel.Item>;
            })
          }
        </Carousel>
      </div>
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
