import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderInfo from './header.info';
import HeaderTab from './header.tab';
import homeLogo from '../../assets/images/home_logo.png';
import { Link } from 'react-router-dom';
import { changeActiveTabItem } from '../../actions/header.actions';
import { bindActionCreators } from 'redux';

class Header extends Component {
    static propTypes = {
      changeActiveTabItem: PropTypes.func.isRequired
    }

    render () {
      return <div className='app-header'>
        <HeaderInfo {...this.props}/>
        <div className='header-content'>
        <Link to='/home' onClick={() => this.props.changeActiveTabItem('HOME')}>
          <div className='header-image'>
            <img src={homeLogo}/>
          </div>
        </Link>
          <div className='header-bottom'>
            <HeaderTab {...this.props}/>
            <a className='brochure-link' download rel='noreferrer' target='_blank' href='http://zincosteel.com/zinco_brochure.pdf'>Brochure</a>
          </div>
        </div>
      </div>;
    }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveTabItem: bindActionCreators(changeActiveTabItem, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
