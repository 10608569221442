import React, { Component } from 'react';
import { connect } from 'react-redux';
import SideMenu from 'react-sidemenu';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { changeActiveServiceItem } from '../../actions';
import { withRouter } from 'react-router';

const sideMenuItems = [
  {
    label: 'Gratings',
    // value: 'GRATINGS',
    value: '/gratings'
  },
  {
    label: 'Cable Trays',
    // value: 'CABLE_TRAYS',
    value: '/cable-trays'
  },
  {
    label: 'Earthing Materials',
    // value: 'EARTHING_MATERIALS',
    value: '/earthing-materials'
  },
  {
    label: 'Chequered Plates',
    // value: 'CHEQUERED_PLATES',
    value: '/chequered-plates'
  },
  {
    label: 'Hand Rails',
    // value: 'HAND_RAILS',
    value: '/hand-rails'
  }
];

class ServicesSidemenu extends Component {
  static propTypes = {
    changeActiveServiceItem: PropTypes.func.isRequired,
    history: PropTypes.object
  }

  // renderSideMenuItem = (item) => {
  //   return <LinkRoute path={item.value}><span>{item.label}</span></LinkRoute>;
  // }

  render () {
    const activeSideMenuItem = window.location.hash && window.location.hash.replace('#', '');
    return <div className='services-sidemenu'>
      <SideMenu
        onMenuItemClick={(value, extras) => {
          this.props.changeActiveServiceItem(value);
          this.props.history.push(value);
        }}
        items={sideMenuItems}
        activeItem={activeSideMenuItem}
        className='sidemenu-custom'
      />
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {},
    servicesState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState,
    servicesState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveServiceItem: bindActionCreators(changeActiveServiceItem, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ServicesSidemenu));
