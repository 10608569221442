import React from 'react';

import cflatimg from '../../../assets/images/service-images/earthing_materials/copper_flat.jpg';
import cwireimg from '../../../assets/images/service-images/earthing_materials/copper_wire.jpg';
import eartflatimg from '../../../assets/images/service-images/earthing_materials/earthing_flats.jpg';
import eartplatimg from '../../../assets/images/service-images/earthing_materials/earthing_plates.jpg';
// import eartstripimg from '../../../assets/images/service-images/earthing_materials/earthing_strips.jpg';
import giroundimg from '../../../assets/images/service-images/earthing_materials/gi_round_earthing_pipe.jpg';
import giwireimg from '../../../assets/images/service-images/earthing_materials/gi_wires.jpg';

const EARTHING_MATERIALS = {
  p1: 'ZINCO STEEL WORKS offers a complete range of high quality electrical earthing materials used widely in earthing systems and playing a vital role in all electrical systems.  Furthermore, these are accredited for their durability, reliability and accuracy. These are used in various industries like Electrical, Electronics, Automobile and Engineering etc. We supply earthing material in various standard specifications, but these can be also custom made as required by the clients.',
  features: [
    'High Load Carrying',
    'Corrosion resistance',
    'Optimal quality',
    'Available in different widths & thickness',
    'Durable',
    'Longer functional life'
  ],
  types: [
    'Earth pipe Electrodes',
    // 'Earthing Strips',
    'GI Wires',
    'Earthing Plates',
    'Copper Wires & Flats'
  ],
  images: {
    giround: {
      name: 'GI Round Earthing Pipe',
      img: giroundimg
    },
    giwire: {
      name: 'GI Wires',
      img: giwireimg
    },
    eartplat: {
      name: 'Earthing Plates',
      img: eartplatimg
    },
    cwire: {
      name: 'Copper Wire',
      img: cwireimg
    },
    cflat: {
      name: 'Copper Flat',
      img: cflatimg
    },
    eartflat: {
      name: 'Earthing Flats',
      img: eartflatimg
    }
    // eartstrip: {
    //   name: 'Earthing Strips',
    //   img: eartstripimg
    // },
  }
};

const EarthingMaterials = ({ ...props }) => {
  return <div className='earthing-materials-container'>
    <div className='content-div'>
    <h4>EARTHING MATERIALS</h4>
      <p>
        {
          EARTHING_MATERIALS.p1
        }
      </p>
      <h6><b>Features</b></h6>
      <ul>
        {
          EARTHING_MATERIALS.features.map((ft, ix) => <li key={ix}>{ft}</li>)
        }
      </ul>
      <h6><b>Types of Materials Offered</b></h6>
      <ul>
        {
          EARTHING_MATERIALS.types.map((ty, ix) => <li key={ix}>{ty}</li>)
        }
      </ul>
    </div>
    <div className='images-div'>
      {
        Object.keys(EARTHING_MATERIALS.images).map((k, ix) => <div className='image-container' key={ix}>
          <img src={EARTHING_MATERIALS.images[k].img} />
          <p>{EARTHING_MATERIALS.images[k].name}</p>
        </div>)
      }
    </div>
  </div>;
};

export default EarthingMaterials;
