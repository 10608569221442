import React, { Component } from 'react';
import { connect } from 'react-redux';
import { COMPANY_ADDRESS, CONTACT_DETAILS } from '../../constants';
import ContactCard from './contact.card';
import Enquiry from './enquiry';

class ContactPage extends Component {
  static propTypes = {
  }

  render () {
    return (
      <div className='contact-container'>
        <div className='contact-details'>
          <div className='contact-details-container'>
            <div className='contact-details-title'>{'Contact us:'}</div>
            {
              CONTACT_DETAILS.map((cd, ix) => <ContactCard details={cd} key={ix} />)
            }
            <div className='contact-card factory-address'>
              <span className='detail-name'>{'Factory Address: '}</span>
              {
                COMPANY_ADDRESS.map((ca, ix) => <span className='address-line' key={ix}>{ca}</span>)
              }
              <a href='https://goo.gl/maps/tCTK1G3tFHSrCnJD7' target="_blank" rel='noreferrer'>Go to maps</a>
            </div>
          </div>
        </div>
        <div className='contact-enquiry'>
          <Enquiry />
        </div>
        <div className='map-component'>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.0467206240405!2d77.79590321481997!3d12.775477890984794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae71296dd65db9%3A0x6c41aa1564b8174b!2sZINCO%20steel%20works!5e0!3m2!1sen!2sin!4v1608039786125!5m2!1sen!2sin"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: '0', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', padding: '8px', background: 'white' }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0">
          </iframe>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
