export const TAB_RECORDS = [
  {
    name: 'HOME',
    hidden: false,
    path: '/home'
  },
  {
    name: 'ABOUT',
    hidden: false,
    path: '/about'
  },
  {
    name: 'SERVICES',
    hidden: false,
    path: '/services'
  },
  {
    name: 'CONTACT',
    hidden: false,
    path: '/contact'
  },
  {
    name: 'GALLERY',
    hidden: true,
    path: '/gallery'
  }
];

export const ROUTE_TAB_MAP = {
  '/': 'HOME',
  '#/': 'HOME',
  '#/home': 'HOME',
  '#/about': 'ABOUT',
  '#/services': 'SERVICES',
  '#/contact': 'CONTACT',
  '#/gallery': 'GALLERY',

  '#/gratings': 'SERVICES',
  '#/cable-trays': 'SERVICES',
  '#/earthing-materials': 'SERVICES',
  '#/chequered-plates': 'SERVICES',
  '#/hand-rails': 'SERVICES'
};

export const HEADER_EMAIL = 'sales@zincosteel.com';
export const HEADER_PHONE = '+91 90421 52882';

export const CONTACT_DETAILS = [
  {
    DESIGNATION: 'Sr. Marketing Manager',
    EMAIL: 'sales@zincosteel.com',
    PHONE: '+91 90421 52882'
  },
  {
    DESIGNATION: 'Jr. Marketing Manager',
    EMAIL: 'info@zincosteel.com',
    PHONE: '+91 95973 87638'
  }
];

export const COMPANY_ADDRESS = [
  'No. 135, Rajeswari Layout,',
  'Sipcot-1,',
  'Hosur - 635126,'
];
