import { CHANGE_ACTIVE_SERVICE_ITEM } from '../constants';

export const servicesState = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case CHANGE_ACTIVE_SERVICE_ITEM:
      newState.activeItem = action.itemValue;
      break;
    default:
      break;
  }
  return newState;
};
