import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import createRootReducer from 'reducers';

const restrictedActions = [];

export const history = createHashHistory();

const middlewares = [thunkMiddleware, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');
  middlewares.push(createLogger({
    predicate: (getState, action) => restrictedActions.indexOf(action.type) === -1
  }));
}

export const configureStore = preloadedState => {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(...middlewares))
  );

  if (module.hot) {
    module.hot.accept('reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
};
