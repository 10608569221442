import React from 'react';
import chplimg from '../../../assets/images/service-images/chequered_plates/chequered_plates.jpg';

const CHEQUERED_PLATES = {
  p1: 'Chequered plate flooring is installed in the plants / Road, where the chances of skidding are high. These Chequered Plate Flooring are available with or without angle frame. We supply hot dip Galvanized, self-colour black-condition, and Painted finish chequered sheets. We also offer a choice of thickness and sizes as per the customer requirements.',
  features: [
    'Wide range of thicknesses and widths, less joints',
    'High skid resistance due to higher friction',
    'Consistency of properties',
    'Bright clean surface'
  ]
};

const ChequeredPlates = ({ ...props }) => {
  return <div className='chequered-plates-container'>
      <div className='content-div'>
      <h4>CHEQUERED PLATES</h4>
      <p>
        {CHEQUERED_PLATES.p1}
      </p>
      <h6><b>Features</b></h6>
      <ul>
          {
            CHEQUERED_PLATES.features.map((ft, ix) => <li key={ix}>{ft}</li>)
          }
        </ul>
      </div>
      <div className='images-div'>
          <div className='image-container'>
            <img src={chplimg}/>
            <p>Chequered Plates</p>
          </div>
      </div>
    </div>;
};

export default ChequeredPlates;
