import React from 'react';
import { HEADER_EMAIL, HEADER_PHONE } from '../../constants/data.constants';

const HeaderInfo = ({ ...props }) => {
  return <div className='header-info'>
        <div>
          <span>
            <i className="fa fa-phone"></i>
            {`  ${HEADER_PHONE}`}
          </span>

          <span>
            <i className="fa fa-envelope"></i>
            {`  ${HEADER_EMAIL}`}
          </span>
        </div>
    </div>;
};

export default HeaderInfo;
