import { ROUTE_TAB_MAP } from './data.constants';

export const INITIAL_APP_STATE = {
  homeState: {

  },
  headerState: {
    activeTabItem: ROUTE_TAB_MAP[window.location.hash || window.location.pathname]
  },
  footerState: {

  },
  servicesState: {
    activeItem: '/gratings'
  }
};

export const COPY_RIGHT_CONTENT = 'All copyrights reserved. © 2020 Website developed and maintained by Sabari Balan R';
