import React from 'react';
import ctaccimg from '../../../assets/images/service-images/cable_trays/cable_tray_accessories.jpg';
import ctcovimg from '../../../assets/images/service-images/cable_trays/cable_tray_cover.jpg';
import laddtypeimg from '../../../assets/images/service-images/cable_trays/ladder_type.jpg';
import perftypeimg from '../../../assets/images/service-images/cable_trays/perforated_type.jpg';
import racewayimg from '../../../assets/images/service-images/cable_trays/raceway_type.jpg';
import ztypeimg from '../../../assets/images/service-images/cable_trays/z_type.jpg';

const CABLE_TRAYS = {
  p1: 'In the electrical wiring of buildings, a cable tray system is used to support insulated electric cables or electrical conduit systems which are used in power distribution and communication. Cable trays are commonly used for cable management in commercial and industrial construction. They are especially useful in situations where changes to a wiring system are anticipated, since new cables can be installed by laying them in the tray, instead of pulling them through a pipe.',
  types: [
    'Ladder Type Cable Trays',
    'Perforated Type Cable Trays',
    'Race Way/Duct',
    'Z-Type Cable Trays',
    'Cable Tray Cover'
  ],
  accessories: [
    'Bends', 'Coupler Plates', 'Nut, Bolt, Washers'
  ],
  images: {
    laddtype: {
      name: 'Ladder Type Cable Trays',
      img: laddtypeimg
    },
    perftype: {
      name: 'Perforated Type Cable Trays',
      img: perftypeimg
    },
    raceway: {
      name: 'Raceway Type Cable Trays',
      img: racewayimg
    },
    ztype: {
      name: 'Z-type Cable Trays',
      img: ztypeimg
    },
    ctcov: {
      name: 'Cable Tray Cover',
      img: ctcovimg
    },
    ctacc: {
      name: 'Cable Tray Accessories',
      img: ctaccimg
    }
  }
};

const CableTrays = ({ ...props }) => {
  return <div className='cable-trays-container'>
    <div className='content-div'>
      <h4>CABLE TRAYS</h4>
      <p>
        {CABLE_TRAYS.p1}
      </p>
      <h6><b>Types of Cable Trays</b></h6>
      <ol>
          {CABLE_TRAYS.types.map((ct, ix) => <li key={ix}>{ct}</li>)}
          <li>
            Cable Tray accessories
                    <ol>
              {
                CABLE_TRAYS.accessories.map((acc, ix) => <li key={ix}>{acc}</li>)
              }
            </ol>
          </li>
        </ol>
    </div>
    <div className='images-div'>
      {
        Object.keys(CABLE_TRAYS.images).map((ct, ix) => <div className='image-container' key={ix}>
          <img src={CABLE_TRAYS.images[ct].img} />
          <p>{CABLE_TRAYS.images[ct].name}</p>
        </div>)
      }
    </div>
  </div>;
};

export default CableTrays;
