import React from 'react';
import { connect } from 'react-redux';
import Header from './header';
import AppBody from './app.body';
import { HashRouter } from 'react-router-dom';

import 'react-sidemenu/dist/side-menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/sass/styles.scss';

class App extends React.Component {
    static propTypes = {
    }

    render () {
      return <React.Fragment>
        <HashRouter>
          <Header {...this.props}/>
          <AppBody {...this.props}/>
        </HashRouter>
      </React.Fragment>;
    }
}

const mapStateToProps = state => {
  const { homeState = {} } = state;
  return {
    homeState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
