import { toast } from 'react-toastify';

export const sendEmail = (emailFrom = '', subject, body, successMessage = 'Email sent', errorMessage = 'Error sending email') => dispatch => {
  // eslint-disable-next-line no-undef
  return Email.send({
    Host: 'smtp.gmail.com',
    Username: 'zincosteelweb@gmail.com',
    Password: 'hyfmqtmpssixmrmo',
    To: 'sales@zincosteel.com',
    From: emailFrom || 'no-reply@zincosteel.com',
    Subject: subject,
    Body: body
  }).then(
    message => {
      if (message === 'OK') {
        toast.success(successMessage);
      } else {
        toast.error(errorMessage);
      }
    }
  );
};
