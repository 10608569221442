import React from 'react';
import { SERVICES_MAIN_CONTENT } from '../../../constants';

const ServicesMain = ({ ...props }) => {
  return <div className='services-main'>
        <p>
            <span>{SERVICES_MAIN_CONTENT.p1s1}</span>
            <span className='bold-text-capital'>{SERVICES_MAIN_CONTENT.p1b1}</span>
            <span>{SERVICES_MAIN_CONTENT.p1s2}</span>
        </p>
        <p>
            {SERVICES_MAIN_CONTENT.p2s1}
        </p>
    </div>;
};

export default ServicesMain;
