import { CHANGE_ACTIVE_TAB_ITEM } from '../constants';

export const headerState = (state = {}, action) => {
  const newState = { ...state };
  switch (action.type) {
    case CHANGE_ACTIVE_TAB_ITEM:
      newState.activeTabItem = action.value;
      break;
    default:
      break;
  }
  return newState;
};
