import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TAB_RECORDS } from '../../constants/data.constants';
import { LinkRoute } from '../common';

class HeaderTab extends Component {
    static propTypes = {
      headerState: PropTypes.object
    }

    render () {
      const { activeTabItem } = this.props.headerState;
      return <div className='header-tab'>
            {
                TAB_RECORDS.filter(tr => !tr.hidden).map((tr, ix) => {
                  const cName = 'tab-item' + (tr.name === activeTabItem ? ' active' : '');
                  return <LinkRoute cName={cName} path={tr.path} key={ix} name={tr.name} />;
                })
            }
        </div>;
    }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTab);
