import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../common/modal';
import Enquiry from '../contact/enquiry';
import ServicesContent from './services.content';
import ServicesSidemenu from './services.sidemenu';

class ServicesPage extends Component {
  static propTypes = {

  }

  state = {
    showEnquiryModal: false
  }

  render () {
    return <React.Fragment>
      <div className='services-container'>
        <div className='services-wrapper'>
          <ServicesSidemenu />
          <ServicesContent />
          <div className='services-footer'>
            <button className='enquiry-button' onClick={() => this.setState({ showEnquiryModal: !this.state.showEnquiryModal })}>
              Enquire Now
          </button>
          </div>
        </div>
      </div>
      <Modal show={this.state.showEnquiryModal} onHide={() => this.setState({ showEnquiryModal: false })}>
        <Enquiry />
      </Modal>
    </React.Fragment>;
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);
