import React, { Component } from 'react';
import _ from 'lodash';

class ContactCard extends Component {
    static propTypes = {
    }

    render () {
      const details = _.get(this, 'props.details', {});
      return <div className='contact-card'>
            {/* <span className='detail-name'>{details.NAME}</span> */}
            <span className='detail-designation'>{details.DESIGNATION}</span>
            <span className='detail-email'>{details.EMAIL}</span>
            <span className='detail-phone'>{details.PHONE}</span>
        </div>;
    }
}

export default ContactCard;
