import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import App from './components/app/index';
import { CHANGE_ACTIVE_SERVICE_ITEM, CHANGE_ACTIVE_TAB_ITEM, INITIAL_APP_STATE, ROUTE_TAB_MAP } from './constants';
import { configureStore, history } from './helpers/store';

const store = configureStore(INITIAL_APP_STATE);

window.addEventListener('hashchange', () => {
  store.dispatch({
    type: CHANGE_ACTIVE_TAB_ITEM,
    value: ROUTE_TAB_MAP[window.location.hash] || 'SERVICES'
  });

  const sidemenuRoutes = [
    '#/gratings',
    '#/cable-trays',
    '#/earthing-materials',
    '#/chequered-plates',
    '#/hand-rails'
  ];

  if (sidemenuRoutes.indexOf(window.location.hash) !== -1) {
    store.dispatch({
      type: CHANGE_ACTIVE_SERVICE_ITEM,
      itemValue: window.location.hash.replace('#', '')
    });
  }
});

export const renderApp = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <App history={history} />
        <ToastContainer />
      </Provider>
    </AppContainer>, document.getElementById('zincosteel-app'));
};

renderApp();

if (module.hot) {
  module.hot.accept('components/app', () => { renderApp(); });
}
