import React, { Component } from 'react';

import { connect } from 'react-redux';

class GalleryPage extends Component {
  static propTypes = {

  }

  render () {
    return <div>
      Home Page Content
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryPage);
