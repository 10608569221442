import React, { Component } from 'react';

import { connect } from 'react-redux';
import { ABOUT_CONTENT_P1_B1, ABOUT_CONTENT_P1_S1, ABOUT_CONTENT_P1_S2, ABOUT_CONTENT_P2_B1, ABOUT_CONTENT_P2_S1, ABOUT_CONTENT_P2_S2, ABOUT_CONTENT_P3, ABOUT_IMAGES } from '../../constants/about.constants';

class AboutPage extends Component {
  static propTypes = {

  }

  render () {
    return <div className='about-container'>
      <div className='about-body'>
      <h4 className='about-title'>ABOUT US</h4>
        <div className='about-content'>
          <p>
            <span>{ABOUT_CONTENT_P1_S1}</span>
            <b>{ABOUT_CONTENT_P1_B1}</b>
            <span>{ABOUT_CONTENT_P1_S2}</span>
          </p>
          <p>
            <span>{ABOUT_CONTENT_P2_S1}</span>
            <b>{ABOUT_CONTENT_P2_B1}</b>
            <span>{ABOUT_CONTENT_P2_S2}</span>
          </p>
          <p>{ABOUT_CONTENT_P3}</p>
        </div>
      </div>
      <div className='about-images'>
          {
            ABOUT_IMAGES.map((ai, ix) => {
              return <img src={ai} key={ix}></img>;
            })
          }
        </div>
    </div>;
  }
}

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
