import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
    static propTypes = {
      children: PropTypes.object,
      show: PropTypes.bool.isRequired,
      onHide: PropTypes.func.isRequired
    }

    render () {
      const backdropStyles = { display: this.props.show ? 'flex' : 'none' };
      return <div className='app-modal-backdrop' style={backdropStyles}>
            <div className='app-modal'>
                <i className='close-icon fa fa-close' onClick={this.props.onHide}></i>
                {this.props.children}
            </div>
        </div>;
    }
}

export default Modal;
