import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeActiveTabItem } from '../../actions/header.actions';
import _ from 'lodash';

const LinkRoute = ({ ...props }) => {
  return (<Link to={props.path}>
    {
       _.isEmpty(props.name)
         ? (
        <div className={props.cName}>
          {props.children}
        </div>
           )
         : (
          <div className={props.cName} onClick={() => props.changeActiveTabItem(props.name)}>
            {
              props.name
            }
          </div>
           )
    }

  </Link>);
};

LinkRoute.propTypes = {
  path: PropTypes.string.isRequired,
  cName: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  changeActiveTabItem: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    homeState = {},
    headerState = {},
    footerState = {}
  } = state;

  return {
    homeState,
    headerState,
    footerState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeActiveTabItem: bindActionCreators(changeActiveTabItem, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LinkRoute));
